import { withDirectives as _withDirectives, vShow as _vShow, resolveComponent as _resolveComponent, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue";
import "core-js/modules/es.error.cause.js";
import "core-js/modules/es.array.push.js";
import { ElMessage, ElLoading } from 'element-plus';
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/loading/style/css'

import { mixins } from "@/plugins/mixins";
export default {
  name: "UserDetail",
  mixins: [mixins],
  data() {
    return {
      userId: null,
      user: {
        userId: null,
        userCode: null,
        userName: null,
        phone: null,
        validateDate: null,
        invalidateDate: null,
        loginType: null,
        lastLoginTime: null,
        enabled: null,
        locked: null,
        remark: null,
        areaList: []
      },
      roles: [],
      logColumns: [{
        label: '操作模块',
        prop: 'model'
      }, {
        label: '操作类型',
        prop: 'name'
      }, {
        label: '请求IP',
        prop: 'remoteIp'
      }, {
        label: '执行结果',
        customRender: props => {
          if (props.row.success) {
            return _createVNode("span", null, [_createVNode("span", {
              "class": "dot dot-success"
            }, null), _createVNode("span", {
              "style": "margin-left: 8px;"
            }, [_createTextVNode("\u6210\u529F")])]);
          } else {
            return _createVNode("span", null, [_createVNode("span", {
              "class": "dot dot-fail"
            }, null), _createVNode("span", {
              "style": "margin-left: 8px;"
            }, [_createTextVNode("\u5931\u8D25")])]);
          }
        }
      }, {
        label: '操作时间',
        prop: 'beginTime'
      }, {
        label: '耗时（ms）',
        prop: 'consume'
      }, {
        label: '备注',
        prop: 'exceptionDesc'
      }],
      usbKeyColumn: [{
        label: '序号',
        prop: 'serialNumber'
      }, {
        label: '签发者',
        prop: 'issuer'
      }, {
        label: '拥有者',
        prop: 'subject'
      }, {
        label: '有效期',
        customRender: props => {
          var _props$row$startTime, _props$row$endTime;
          return _createVNode("span", null, [(_props$row$startTime = props.row.startTime) !== null && _props$row$startTime !== void 0 ? _props$row$startTime : '-', _createTextVNode(" \u81F3 "), (_props$row$endTime = props.row.endTime) !== null && _props$row$endTime !== void 0 ? _props$row$endTime : '长期']);
        }
      }, {
        label: '使用状态',
        customRender: props => {
          if (this.$hasPermission('user_cert_enable')) {
            return _createVNode(_resolveComponent("el-switch"), {
              "modelValue": props.row.enable,
              "onUpdate:modelValue": $event => props.row.enable = $event,
              "loading": this.switchLoading,
              "before-change": () => {
                return new Promise((resolve, reject) => {
                  this.changeCertEnable(props.row, resolve, reject);
                });
              }
            }, null);
          } else {
            return props.row.enable ? '使用中' : '已禁用';
          }
        }
      }],
      permissionColumns: [{
        label: '权限名称',
        align: 'left',
        customRender: props => {
          return _createVNode("span", null, [_withDirectives(_createVNode(_resolveComponent("el-icon"), {
            "class": ["iconfont", props.row.icon],
            "size": "16px",
            "style": "margin-right:5px"
          }, null), [[_vShow, props.row.icon]]), props.row.permissionName]);
        }
      }, {
        prop: 'permissionCode',
        label: '权限编码'
      }, {
        prop: 'type',
        label: '类型'
      }],
      switchLoading: false
    };
  },
  methods: {
    changeEnabled() {
      return new Promise((resolve, reject) => {
        this.switchLoading = true;
        const callBack = res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            return resolve(true);
          } else {
            ElMessage.error(res.message);
            return reject(new Error('Error'));
          }
        };
        if (this.user.enabled) {
          this.$api.uac.user.disable(this.user.userId).then(callBack).finally(() => this.switchLoading = false);
        } else {
          this.$api.uac.user.enable(this.user.userId).then(callBack).finally(() => this.switchLoading = false);
        }
      });
    },
    changeLock() {
      return new Promise((resolve, reject) => {
        this.switchLoading = true;
        const callBack = res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            if (resolve) {
              return resolve(true);
            }
          } else {
            ElMessage.error(res.message);
            if (reject) {
              return reject(new Error('Error'));
            }
          }
        };
        if (this.user.locked) {
          this.$api.uac.user.unLock(this.user.userId).then(callBack).finally(() => this.switchLoading = false);
        } else {
          this.$api.uac.user.lock(this.user.userId).then(callBack).finally(() => this.switchLoading = false);
        }
      });
    },
    toBindUser() {
      this.$router.push('/role');
    },
    toDeptBindUser() {
      this.$router.push('/dept');
    },
    changeCertEnable(cert, resolve, reject) {
      this.switchLoading = true;
      const callBack = res => {
        if (res.code === 200) {
          ElMessage({
            message: res.message,
            type: 'success'
          });
          this.$refs.certTableRef.fetch();
          if (resolve) {
            return resolve(true);
          }
        } else {
          ElMessage.error(res.message);
          if (reject) {
            return reject(new Error('Error'));
          }
        }
      };
      if (cert.enable) {
        this.$api.uac.user.disableUsbKey(this.userId, cert.id).then(callBack).finally(() => this.switchLoading = false);
      } else {
        this.$api.uac.user.enableUsbKey(this.userId, cert.id).then(callBack).finally(() => this.switchLoading = false);
      }
    }
  },
  beforeMount() {
    if (this.$route.query.userId) {
      this.userId = this.$route.query.userId;
      const loading = ElLoading.service();
      this.$api.uac.user.detail(this.userId).then(res => {
        if (res.code === 200) {
          this.user = Object.assign(this.user, res.data);
        } else if (res.code === 101102) {
          this.$router.replace({
            name: '404'
          });
        } else {
          ElMessage.error(res.message);
          this.$router.back();
        }
      }).finally(() => loading.close());

      // 查询角色
      this.$api.uac.role.queryByUser(this.userId).then(res => {
        if (res.code === 200) {
          this.roles = res.data;
        }
      });
    } else {
      this.$router.back();
    }
  }
};